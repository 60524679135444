<template>
    <div>
        <v-alert class="mx-4" type="success" v-if="datos.length < 1">Sin liderazgos pendientes</v-alert>
        <v-list>
            <v-list-group prepend-icon="assignment" v-for="(item, i) in datos" v-bind:key="i">
                <template v-slot:activator>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ item.actividad }} ({{ (item.progreso / 100) | decimal_percent }})
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ item.total_tareas }} tarea(s), {{ item.total_pendientes }} pendiente(s).
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <v-progress-linear :value="item.progreso" color="success" striped rounded height="12" />
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </template>
                <v-list-item v-for="(tarea, j) in item.tareas" v-bind:key="j">
                    <v-list-item-action>
                        <v-icon :color="color(tarea.estado_tiempo)">label</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>{{ tarea.texto }}</v-list-item-title>
                        <v-list-item-subtitle v-if="get_days(tarea.vencimiento) > 0">
                            Vence en {{ get_days(tarea.vencimiento) }} día(s). [{{ tarea.vencimiento | moment('L') }}]
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-else-if="get_days(tarea.vencimiento) < 0">
                            Atrasada por {{ Math.abs(get_days(tarea.vencimiento)) }} día(s). [{{ tarea.vencimiento | moment('L') }}]
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-else>
                            ¡Vence hoy! [{{ tarea.vencimiento | moment('L') }}]
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
        </v-list>
    </div>
</template>

<script>
import { color_estados } from '@/helpers'
import moment from 'moment'

export default {
    props: ['datos'],
    methods: {
        color(estado) {
            return color_estados(estado, 'tiempo');
        },
        get_days(fecha) {
            var a = moment(fecha);
            var b = moment().set(0, 'hours').set(0, 'minutes').set(0, 'seconds');
            return a.diff(b, 'days');
        }
    }
}
</script>