<template>
    <div>
        <v-row dense>
            <v-col cols="12" align="center">
                <v-btn-toggle dense mandatory rounded class="ma-2" @change="zoom" v-model="toggle_zoom">
                    <v-btn :value="-2"><span class="subtitle-1">1 Hr</span></v-btn>
                    <v-btn :value="-1"><span class="subtitle-1">6 Hr</span></v-btn>
                    <v-btn :value="0"><span class="subtitle-1">12 Hr</span></v-btn>
                    <v-btn :value="1"><span class="subtitle-1">1 día</span></v-btn>
                    <v-btn :value="2"><span class="subtitle-1">2 días</span></v-btn>
                    <v-btn :value="3"><span class="subtitle-1">3 días</span></v-btn>
                    <v-btn :value="4"><span class="subtitle-1">Todo</span></v-btn>
                </v-btn-toggle>
            </v-col>
            <v-col cols="12">
                <VueApexCharts 
                    responsive
                    ref="grafico" 
                    width="100%" 
                    type="line" 
                    height="350" 
                    :options="main_options" 
                    :series="series">
                </VueApexCharts>
            </v-col>
            <v-col cols="12" align="center">
                <v-btn class="ma-1" large depressed color="secondary" @click="move_left">
                    <v-icon large>chevron_left</v-icon>
                </v-btn>
                <v-btn class="ma-1" large depressed color="secondary" @click="move_right">
                    <v-icon large>chevron_right</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'

export default {
    components: {
        VueApexCharts
    },
    props: {
        xaxis: {
            type: Object,
            default: () => ({
                type: 'numeric'
            })
        },
        yaxis: {
            type: Object,
            default: () => ({
                labels: { formatter: (val) => val }
            })
        },
        tooltip: {
            type: Object,
            default: () => ({
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            })
        },
        series: {
            type: Array,
            default: () => ([])
        }
    },
    data: () => ({
        movement: 0,
        toggle_zoom: 4,
        main_options: {
            chart: {
                animations: { enabled: false },
                stacked: false,
                height: 350,
                toolbar: { show: false },
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                }
            },
            xaxis: {
                type: 'datetime',
            },
            yaxis: {
                title: {
                    text: 'MP10 (ug/m3)'
                },
            },
            legend: {
                show: true,
                fontSize: 15,
                markers: {
                    width: 16,
                    height: 16,
                    radius: 2
                },
                itemMargin: { horizontal: 5 },
                itemOnClick: { toggleDataSeries: false }
            },
            stroke: { width: 2 },
            dataLabels: { enabled: false },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            },
            responsive: [{
                breakpoints: 500,
                options: {
                    yaxis: {
                        show: false
                    }
                }
            }]
        }
    }),
    methods: {
        move(amount) {
            
            let showAll = this.toggle_zoom == 4;

            let minValue = this.series[0].data[2][0];
            let maxValue = this.series[0].data[2][0];
            let prop = [1, 'day'];

            if(this.toggle_zoom == -2) prop = [1, 'hour'];
            if(this.toggle_zoom == -1) prop = [12, 'hour'];
            if(this.toggle_zoom == 0) prop = [6, 'hour'];
            if(this.toggle_zoom == 1) prop = [1, 'day'];
            if(this.toggle_zoom == 2) prop = [2, 'day'];
            if(this.toggle_zoom == 3) prop = [3, 'day'];

            if(amount < 0) {
                minValue = moment(this.series[0].data[0][0]).subtract(Math.abs(amount) * prop[0], prop[1]).valueOf();
                maxValue = moment(this.series[0].data[0][0]).subtract(Math.abs(amount + 1) * prop[0], prop[1]).valueOf();
            } else {
                minValue = moment(this.series[0].data[0][0]).add(Math.abs(amount) * prop[0], prop[1]).valueOf();
                maxValue = moment(this.series[0].data[0][0]).add(Math.abs(amount + 1) * prop[0], prop[1]).valueOf();
            }

            let length = this.series[0].data.length - 1;

            if(this.toggle_zoom == 4) {
                minValue = this.series[0].data[0][0];
                maxValue = this.series[0].data[length][0];
            }

            console.log([minValue, maxValue]);

            this.update();
            this.$refs.grafico.updateOptions({
                xaxis: {
                min: minValue,
                max: maxValue
                }
            })
        },
        move_left() {
            this.movement -= 1;
            this.move(this.movement);
        },
        move_right() {
            this.movement += 1;
            this.move(this.movement);
        },
        zoom(e) {
            this.move(this.movement);
        },
        update() {
            var _new = {... this.options };
            this.main_options = this.update_apex(_new);
        },
        update_apex(_new) {
            _new.xaxis = this.xaxis;
            _new.yaxis = this.yaxis;
            _new.tooltip = this.tooltip;
            return _new;
        }
    },
    watch: {
        series: function(_new, old)  {
            if(!_new) return;
            console.log(this.series);
        },
        xaxis: function() {
            var _new = {... this.options };
            this.main_options = this.update_apex(_new);
        },
        yaxis: function() {
            var _new = {... this.options };
            this.main_options = this.update_apex(_new);
        }
    }
}
</script>